import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout-en"
import ImagePaula from "../../components/imagePaula-en"
import ImageVirginie from "../../components/imageVirginie-en"
import ImageCyril from "../../components/imageCyril-en"
import styled from "@emotion/styled"
import SEO from "../../components/seo-en"

const EquipePage = () => (
  <Layout pathFr="/equipe/">
    <SEO
      title="Team - Dr Philippe Roure"
      description="Presentation of the team Dr. Philippe Roure, surgeon of the upper limb in Paris: Virginie and Paula are medical assistants, Cyril is operating aid ."
    />
    <Helmet>
      <link
        rel="alternate"
        hreflang="fr"
        href={`https://www.docteurphilipperoure.com/equipe/`}
      />
    </Helmet>
    <Wrapper>
      <H1>Team</H1>
      <p>
        <strong>Virginie</strong> and <strong>Paula</strong>, Dr. Philippe
        Roure's two assistants, will welcome you and help you effectively
        organise your procedure, and any additional examinations. They can be
        contacted by phone and can provide information directly or through Dr.
        Roure. Virginie is based at the{" "}
        <Link to="/en/consultation/">Espace Médical Vauban</Link>, and Paula at
        the Paris V Clinique du Sport.
      </p>
      <p>
        <strong>Cyril</strong> is a registered operating theatre nurse. He
        assists Dr. Roure in the operating theatre during the procedures he
        performs at the <Link to="/en/clinic">Clinique du Sport Paris V</Link>.
        He will be with you in the operating room prior to your procedure.
      </p>
      <p>
        Dr. Roure practices at the Espace Médical Vauban with{" "}
        <strong>fellow surgeons and doctors</strong> who are specialists in
        orthopaedics, trauma, and the various sport and osteoarthritis
        pathologies. In <strong>the hand upper limb surgery unit</strong> of the
        Espace Médical Vauban, Dr. Roure collaborates with Dr. Fontès. They work
        together on the most complex cases and provide personalised follow-up
        for their patients.
      </p>
      <Row>
        <Col>
          <ImagePaula />
          <Legende>Paula</Legende>
          <Hr />
          <Metier>Medical assistant</Metier>
        </Col>
        <Col>
          <ImageVirginie />
          <Legende>Viriginie</Legende>
          <Hr />
          <Metier>Medical assistant</Metier>
        </Col>
        <Col>
          <ImageCyril />
          <Legende>Cyril</Legende>
          <Hr />
          <Metier>Operating aid </Metier>
        </Col>
      </Row>
    </Wrapper>
  </Layout>
)

export default EquipePage

const Wrapper = styled.div`
  background-color: white;
  border-radius: 5px;
  margin: 0.5em auto 0.5em;
  padding: 0.5em 2em 1em 2em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 678px) {
    padding: 1em;
  }
`

const H1 = styled.h1`
  margin: 25px 0 25px;
`
const Row = styled.div`
  display: flex;
  margin-top: 25px;
  @media (max-width: 678px) {
    display: block;
  }
`

const Col = styled.div`
  flex: 33.3%;
  padding: 0 40px;
`

const Legende = styled.div`
  text-align: center;
  font-weight: 500;
  margin-top: 15px;
  letter-spacing: 0.04em;
  font-size: 1.23em;
`
const Hr = styled.hr`
  width: 18%;
  margin: 6px auto 2px;
  color: #4f5767;
`

const Metier = styled.div`
  text-align: center;
  font-size: 0.89em;
  color: #4f5767;
  @media (max-width: 678px) {
    margin-bottom: 2em;
  }
`
